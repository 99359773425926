<template>
  <div>
    <CRow>
      <CCol lg="12">
        <CCard>
          <CCardHeader>
            <slot name="header">
              <div class="prt-card-header">
                <div class="header-naming">
                  <CIcon name="cil-graph"/>
                  Statistiken
                </div>
                <download-csv :data="this.json_data" :name="csv_name">
                  <button type="button" class="btn btn-success">
                    Export (CSV)
                  </button>
                </download-csv>
              </div>
            </slot>
          </CCardHeader>
          <CCardBody>
            <CDataTable
                    :items="tableData"
                    :itemsPerPageSelect="{ label: 'Besucher pro Seite', values: [10, 20, 30, 50] }"
                    :fields="getTableFields()"
                    :noItemsView="{ noResults: 'Keine Suchergebnisse verfügbar',
                    noItems: 'Keine Statistiken verfügbar' }"
                    hover
                    striped
                    bordered
                    fixed
                    :column-filter="false"
                    :tableFilter="{ label: 'Besucher suchen', placeholder: 'Suchbegriff eingeben', external: true, lazy: true }"
                    @update:table-filter-value="updateTableFilter"
                    :sorter="{ external: true, resetable: true }"
                    :sorterValue.sync="sorterValue"
                    :pagination="false"
                    @pagination-change="updateItemsPerPage"
                    :loading="tableShowLoading"
            >
              <template #under-table="{item}">
                <CPagination
                    v-if="pagination"
                    v-show="pagination.totalPages > 1"
                    :activePage="pagination.activePage"
                    @update:activePage="activePageChanged"
                    :pages="pagination.totalPages"
                />
              </template>
              <template #type="{item}">
                <td>
                  <CBadge :color="getTableBadge(item.type)">
                    {{item.type}}
                  </CBadge>
                </td>
              </template>
              <template v-slot:lastStatistic.date="{ item }">
                <td>
                  {{ $moment(item['lastStatistic.date']).format($commons.DATE_TIME_FORMAT) }}
                </td>
              </template>
              <template v-slot:lastStatistic.utm_source="{ item }">
                <td>
                  {{ item['lastStatistic.utm_source'] || '–' }}
                </td>
              </template>
              <template v-slot:lastStatistic.utm_campaign="{ item }">
                <td>
                  {{ item['lastStatistic.utm_campaign'] || '–' }}
                </td>
              </template>
              <template v-slot:lastStatistic.utm_medium="{ item }">
                <td>
                  {{ item['lastStatistic.utm_medium'] || '–' }}
                </td>
              </template>
              <template v-slot:lastStatistic.utm_term="{ item }">
                <td>
                  {{ item['lastStatistic.utm_term'] || '–' }}
                </td>
              </template>
              <template v-slot:lastStatistic.utm_content="{ item }">
                <td>
                  {{ item['lastStatistic.utm_content'] || '–' }}
                </td>
              </template>

              <!--<template #show_details="{item, index}">
                <td class="py-2">
                  <CButton
                          :to="'leads/edit/' + item.id"
                          color="primary"
                          variant="outline"
                          square
                          size="sm"
                  >
                    Edit
                  </CButton>
                </td>
              </template>-->
            </CDataTable>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<style scoped>
  .prt-card-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .prt-card-header > div {
    text-align: right;
  }
</style>
<script>
import axios from 'axios'
import commons from '@/commons'
import CPagination from '@coreui/vue/src/components/pagination/CPagination'

export default {
  name: 'Statistics',
  components: { CPagination },
  data() {
    return {
      tableData: [],
      tableShowLoading: true,
      json_data: [],
      csv_name: new Date().getTime() + '.csv',
      pagination: { activePage: 1 },
      sorterValue: { column: 'lastStatistic.date', asc: false },
      tableFilterText: null,
      itemsPerPage: 10
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    getTableFields() {
      return [
        {key: 'lastStatistic.date', label: 'Datum'},
        {key: 'browser', label: 'Browser'},
        {key: 'operatingSystem', label: 'OS'},
        {key: 'device', label: 'Gerät'},
        {key: 'deviceClass', label: 'Typ'},
        {key: 'country', label: 'Land'},
        {key: 'city', label: 'Stadt'},
        {key: 'language', label: 'Sprache'},
        {key: 'lastStatistic.step', label: 'Schritt'},
        {key: 'ip', label: 'IP'},
        {key: 'lastStatistic.utm_source', label: 'UTM Source'},
        {key: 'lastStatistic.utm_campaign', label: 'UTM Campaign'},
        {key: 'lastStatistic.utm_medium', label: 'UTM Medium'},
        {key: 'lastStatistic.utm_term', label: 'UTM Term'},
        {key: 'lastStatistic.utm_content', label: 'UTM Content'},
        /* {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        } */
      ]
    },
    fillJsonDataForCSV(responseData) {
      let new_array = []
      for( let i = 0; i < responseData.length; i ++) {
        const data = {
          'Datum': this.$moment(responseData[i].lastStatistic.date).format(this.$commons.DATE_TIME_FORMAT),
          'Browser': responseData[i].browser,
          'OS': responseData[i].operatingSystem,
          'Geraet': responseData[i].device,
          'Typ': responseData[i].deviceClass,
          'Land': responseData[i].country,
          'Stadt': responseData[i].city,
          'Sprache': responseData[i].language,
          'Schritt': responseData[i].lastStatistic.step,
          'IP': responseData[i].ip,
          'UTM Source': responseData[i].lastStatistic.utm_source,
          'UTM Campaign': responseData[i].lastStatistic.utm_campaign,
          'UTM Medium': responseData[i].lastStatistic.utm_medium,
          'UTM Term': responseData[i].lastStatistic.utm_term,
          'UTM Content': responseData[i].lastStatistic.utm_content,
        }
        new_array.push(data)
      }
      this.json_data = new_array;
    },
    fetchData() {
      this.tableShowLoading = true
      axios.get(`/statistic`, {
        params: {
          size: this.itemsPerPage,
          page: this.pagination.activePage - 1,
          sort: this.sorterValue.column === null ? null : `${this.sorterValue.column},${this.sorterValue.asc ? 'asc' : 'desc'}`,
          tableFilter: this.tableFilterText ? this.tableFilterText : null
        }
      }).then(response => {
            let content = []
            const responseData = response.data
            responseData.content.forEach(value => {
              content.push(commons.flatObject(value))
            })
            this.tableData = content
            this.fillJsonDataForCSV(responseData)
            this.pagination = {
              activePage: responseData.number + 1,
              totalPages: responseData.totalPages,
              size: '',
            }
          })
          .catch(err => console.error('Problem while fetching statistics', err))
          .finally(() => this.tableShowLoading = false)
    },
    updateItemsPerPage(newItemsPerPage) {
      this.itemsPerPage = newItemsPerPage
      this.fetchData()
    },
    activePageChanged(newActivePage) {
      this.pagination.activePage = newActivePage
      this.fetchData()
    },
    updateTableFilter(tableFilterText) {
      this.tableFilterText = tableFilterText
      this.fetchData()
    }
  },
  watch: {
    sorterValue() {
      this.fetchData()
    }
  }
}
</script>
